import './App.css';
import Countdown from "react-countdown";

function App() {
    // Renderer callback with condition
    const renderer = ({ days, hours, minutes, seconds }) => <div><span>{days}</span> ימים, <span>{hours}</span> שעות, <span>{minutes}</span> דקות ו<span>{seconds}</span> שניות</div>

    return (
        <div className="App">
            <header className="App-header">
                <p dir={'rtl'}>
                    <Countdown date={new Date(2022, 6, 14)} renderer={renderer} />
                </p>
            </header>
        </div>
    );
}

export default App;
